import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconLock, IconMail } from '@tabler/icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { Button, Flex, Input, Form, Divider, Spin, notification } from 'antd';

import Account from '../Account';
import { Helmet } from 'react-helmet';
import router from '~/configs/routes';
import { app } from '~/firebase/config';
import logoGitHub from '~/assets/image/github.png';
import logoGoogle from '~/assets/image/logo-google.png';
import { loginUserSuccess, logoutUserSuccess } from '~/redux/reducer/auth';
import { startLoadingGlobal, stopLoadingGlobal } from '~/redux/reducer/loading';
import { requestUserGetCurrent, requestUserLoginGoogle, requestUserLogin, requestUserVerifyLogin } from '~/services/auth';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isVerify, setIsVerify] = useState(false);
    const [verifyType, setVerifyType] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirect_url');

    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        if (currentUser) {
            const fetch = async () => {
                dispatch(startLoadingGlobal());
                const result = await requestUserGetCurrent();

                dispatch(stopLoadingGlobal());
                if (result?.status === 200) {
                    dispatch(loginUserSuccess(result.data));
                    navigate(router.home);
                } else {
                    dispatch(logoutUserSuccess());
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleVerifyOtpLogin = async (values) => {
        const { otp } = values;

        if (!otp || otp.length !== 6) {
            return notification.error({ message: 'Thông báo', description: 'Mã xác minh không hợp lệ' });
        }

        setLoadingButton(true);
        const data = {
            otp,
            email,
            password,
            type: verifyType,
        };

        const result = await requestUserVerifyLogin(data);

        setLoadingButton(false);
        if (result?.status === 200) {
            dispatch(loginUserSuccess(result.data));
            navigate(redirectUrl || router.home);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleLoginEmail = async (data) => {
        setEmail(data.email);
        setLoadingButton(true);
        setPassword(data.password);

        const result = await requestUserLogin(data);

        setLoadingButton(false);
        if (result?.status === 1) {
            navigate(`${router.verify}?email=${data.email}`);
        } else if (result?.status === 2) {
            setIsVerify(true);
            setVerifyType(result.data);
        } else if (result?.status === 200) {
            dispatch(loginUserSuccess(result.data));
            navigate(redirectUrl || router.home);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleLoginGoogle = async () => {
        try {
            const auth = getAuth(app);
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            dispatch(startLoadingGlobal());
            const token = await result.user.getIdToken();

            const response = await requestUserLoginGoogle({ token });

            dispatch(stopLoadingGlobal());
            if (response.status === 200) {
                dispatch(loginUserSuccess(response.data));
                navigate(redirectUrl || router.home);
                window.location.reload();
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo',
                description: 'Lỗi đăng nhập bằng google',
            });
        }
    };

    const handleLoginGithub = async () => {
        notification.error({
            message: 'Thông báo',
            description: 'Chức năng đang được phát triển',
        });
    };

    const onChange = (otp) => {
        if (otp.length === 6) {
            form.submit();
        }
    };

    const sharedProps = {
        onChange,
    };

    return (
        <Account>
            <Helmet>
                <title>Thegioicode.com - Đăng nhập tài khoản</title>
                <meta key="description" name="description" content="Đăng nhập tài khoản để bắt đầu sử dụng dịch vụ của chúng tôi" />

                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`https://thegioicode.com${router.login}`} />
                <meta property="og:url" content={`https://thegioicode.com${router.login}`} />
                <meta property="og:title" content="Thegioicode.com - Đăng nhập tài khoản" />
                <meta property="og:image" content="https://thegioicode.com/images/izgdwacbGc.png" />
                <meta property="og:description" content="Đăng nhập tài khoản để bắt đầu sử dụng dịch vụ của chúng tôi" />
            </Helmet>

            {isVerify && verifyType ? (
                <Fragment>
                    <h3 className="mb-1 font-weight-bold font-size-30">Xác thực OTP</h3>
                    <p className="mb-6 text-left">
                        {verifyType === 'Email' ? (
                            'Một mã xác minh đã được gửi đến địa chỉ email của bạn. Mã xác minh sẽ có hiệu lực trong 5 phút. Vui lòng nhập mã vào ô bên dưới để tiếp tục.'
                        ) : (
                            <Fragment>
                                Bạn đã bật <b>Google Authenticator</b> để xác thực. Vui lòng mở ứng dụng <b>Google Authenticator</b> để nhận
                                mã và nhập mã vào ô bên dưới để tiếp tục.
                            </Fragment>
                        )}
                    </p>
                    <Form form={form} name="horizontal_login" onFinish={handleVerifyOtpLogin}>
                        <Form.Item
                            name="otp"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mã xác nhận',
                                },
                            ]}
                        >
                            <Input.OTP
                                length={6}
                                autoFocus
                                size="large"
                                placeholder="Mã xác nhận"
                                {...sharedProps}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" size="large" disabled={loadingButton} style={{ width: '100%' }}>
                            {loadingButton ? <Spin /> : <Fragment>Xác nhận</Fragment>}
                        </Button>
                    </Form>
                </Fragment>
            ) : (
                <Fragment>
                    <h3 className="mb-1 font-weight-bold font-size-30">Welcome Back</h3>
                    <p className="mb-6 text-center">Chào mừng bạn trở lại! Vui lòng nhập thông tin của bạn.</p>
                    <Form form={form} name="horizontal_login" onFinish={handleLoginEmail}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập email',
                                },
                                {
                                    type: 'email',
                                    message: 'Email không đúng định dạng',
                                },
                            ]}
                        >
                            <Input size="large" autoFocus prefix={<IconMail />} placeholder="Nhập địa chỉ email của bạn" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mật khẩu',
                                },
                                {
                                    pattern: /^\S{6,30}$/,
                                    message: 'Mật khẩu không hợp lệ',
                                },
                            ]}
                            style={{ marginBottom: 12 }}
                        >
                            <Input.Password
                                size="large"
                                prefix={<IconLock />}
                                placeholder="Nhập mật khẩu của bạn"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        <Flex justify="end" style={{ marginBottom: 12 }}>
                            <Link
                                to={router.reset}
                                onClick={() => notification.error({ message: 'Thông báo', description: 'Chức năng đang được phát triển' })}
                            >
                                Quên mật khẩu?
                            </Link>
                        </Flex>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button type="primary" htmlType="submit" size="large" disabled={loadingButton} style={{ width: '100%' }}>
                                    {loadingButton ? <Spin /> : <Fragment>ĐĂNG NHẬP</Fragment>}
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                    <Divider style={{ color: '#7a869a' }}>Hoặc thông qua</Divider>
                    <Flex justify="center" gap={10}>
                        <Button
                            size="large"
                            style={{ display: 'flex', alignItems: 'center', borderRadius: 40 }}
                            onClick={handleLoginGoogle}
                        >
                            <img src={logoGoogle} alt="Google" className="login-google" />
                        </Button>
                        <Button
                            size="large"
                            style={{ display: 'flex', alignItems: 'center', borderRadius: 40 }}
                            onClick={handleLoginGithub}
                        >
                            <img src={logoGitHub} alt="GitHub" className="login-google" />
                        </Button>
                    </Flex>

                    <div className="text-center mt-4">
                        Bạn chưa có tài khoản? <Link to={router.register}>Đăng ký ngay</Link>
                    </div>
                </Fragment>
            )}

            <Flex justify="center" className="font-size-14 mt-6 gap-5">
                <Link target="_blank" to="/dieu-khoan-su-dung">
                    Điều khoản
                </Link>
                <Link target="_blank" to="/chinh-sach-bao-mat">
                    Bảo mật
                </Link>
                <Link target="_blank" to="/cam-ket-dich-vu">
                    Cam kết dịch vụ
                </Link>
            </Flex>
        </Account>
    );
}

export default Login;
