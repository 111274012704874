import { Collapse, Flex, Table } from 'antd';

const columns = [
    {
        title: 'Loại',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Tham số',
        dataIndex: 'parameter',
        key: 'parameter',
    },
    {
        title: 'Kiểu dữ liệu',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description',
    },
];

const dataSource = [
    {
        title: 'Body JSON',
        parameter: 'plan_id',
        type: 'Number | String',
        description: 'ID của loại máy chủ',
    },
    {
        title: 'Body JSON',
        parameter: 'region_id',
        type: 'Number | String',
        description: 'ID vị trí đặt máy chủ',
    },
    {
        title: 'Body JSON',
        parameter: 'product_id',
        type: 'Number | String',
        description: 'ID gói dịch vụ muốn đăng ký',
    },
    {
        title: 'Body JSON',
        parameter: 'display_name',
        type: 'Array',
        description: 'Mảng danh sách tên máy chủ',
    },
    {
        title: 'Body JSON',
        parameter: 'image_id',
        type: 'Number | String',
        description: 'ID hệ điều hành muốn cài đặt',
    },
    {
        title: 'Body JSON',
        parameter: 'pricing_id',
        type: 'Number | String',
        description: 'ID chu kỳ máy chủ muốn đăng ký',
    },
    {
        title: 'Body JSON',
        parameter: 'count',
        type: 'Number',
        description: 'Số lượng máy chủ muốn khởi tạo tối đa 10',
    },
];

const example = {
    plan_id: 28836824,
    region_id: 82206976,
    display_name: ['ubuntu2004'],
    image_id: 23695313,
    product_id: 32483388,
    pricing_id: 76315442,
    count: 1,
};

const responseSuccess = {
    data: [
        {
            id: 38260927,
            order_info: {
                port: 22,
                hostname: 'ubuntu2004',
                username: 'root',
                password: 'M!v1orI0#8Qj',
                access_ipv4: '161.248.4.8',
                access_ipv6: '',
            },
            plan: {
                id: 28836824,
                title: 'Premium',
                image_url: 'https://thegioicode.com/images/TrchbOWyGD.png',
                description:
                    'Ứng dụng đòi hỏi hiệu năng cao - Trang bị CPU Intel Xeon Gold/Platinum Gen 2 cùng với ổ cứng Enterprise NVMe siêu siêu nhanh',
            },
            region: {
                id: 82206976,
                title: 'Hồ Chí Minh',
                image_url: 'https://thegioicode.com/images/yBdMTontlE.png',
                description: '',
            },
            image: {
                id: 23695313,
                title: 'Debian-9.4_64bit',
                group: 'Debian',
                image_url: 'https://thegioicode.com/images/MWDpXuAIPW.png',
                description: '',
            },
            product: {
                id: 32483388,
                disk: 40,
                core: 2,
                title: 'Premium #3',
                memory: 2048,
                customize: false,
                bandwidth: 0,
                description: '',
                network_speed: 200,
            },
            status: 'starting',
            method: 'api',
            slug_url: '2dde3629-0375-4adb-b69a-1f7633355269',
            cpu_usage: 0,
            auto_renew: false,
            disk_usage: 0,
            description: '',
            memory_usage: 0,
            display_name: 'ubuntu2004',
            backup_server: false,
            override_price: 756000,
            bandwidth_usage: 0,
            created_at: '2025-01-13 23:23:37',
            expired_at: '2025-07-13 23:59:59',
        },
        '...',
    ],
    status: 200,
    message: 'Khởi tạo máy chủ thành công',
};

function Deploy() {
    return (
        <Collapse
            items={[
                {
                    key: '1',
                    label: <div className="font-bold text-hover">Khởi tạo dịch vụ</div>,
                    children: (
                        <div>
                            <Flex align="center">
                                <span className="document-method text-warning font-bold">POST</span>
                                <span className="document-method flex-1 text-start">
                                    https://thegioicode.com/api/v2/cloud-server/deploy
                                </span>
                            </Flex>

                            <Table className="mt-4" bordered columns={columns} dataSource={dataSource} pagination={false} />

                            <div className="mt-3 mb-1">Body:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy warning">{JSON.stringify(example, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>

                            <div className="mt-3 mb-1">Thành công:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy success">{JSON.stringify(responseSuccess, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>

                            <div className="mt-3 mb-1">Thất bại:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy">{JSON.stringify({ error: 'Thông tin lỗi trả về' }, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    );
}

export default Deploy;
