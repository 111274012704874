import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconArrowLeft, IconCheck, IconTicket, IconTrash } from '@tabler/icons-react';
import { Avatar, Breadcrumb, Button, Card, Col, Flex, Input, message, Modal, notification, Radio, Row, Select, Spin, Table } from 'antd';

import router from '~/configs/routes';
import { convertCurrency } from '~/configs';
import imagePoint from '~/assets/image/point.png';
import imageWallet from '~/assets/image/wallet.png';
import imageEmptyCart from '~/assets/image/empty-cart.png';
import { loginUserSuccess, logoutUserSuccess } from '~/redux/reducer/auth';
import { requestUserClearCart, requestUserPaymentCart, requestUserGetCart, requestUserChangeCycles } from '~/services/cart';

const { Option } = Select;

function Cart() {
    const [carts, setCarts] = useState([]);
    const [voucher, setVoucher] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [totalBonusPoint, setTotalBonusPoint] = useState(0);
    const [loadingPayment, setLoadingPayment] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = 'Thegioicode.com - Giỏ hàng';

        const fetch = async () => {
            setLoading(true);

            const result = await requestUserGetCart();

            setLoading(false);
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${router.login}?redirect_url=${pathname}`);
            } else if (result.status === 200) {
                const totalPrice = result.data.reduce((price, current) => {
                    return price + current.pricing.price * current.quantity;
                }, 0);

                const totalDiscount = result.data.reduce((discount, current) => {
                    return discount + current.pricing.price * (current.pricing.discount / 100) * current.quantity;
                }, 0);

                const totalPayment = result.data.reduce((total, current) => {
                    return total + current.pricing.price * (1 - current.pricing.discount / 100) * current.quantity;
                }, 0);

                const totalBonusPoint = result.data.reduce((total, current) => {
                    return total + current.pricing.bonus_point;
                }, 0);

                setCarts(result.data);
                setTotalPrice(totalPrice);
                setTotalPayment(totalPayment);
                setTotalDiscount(totalDiscount);
                setTotalBonusPoint(totalBonusPoint);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectCycles = async (cycles_id, option_id) => {
        const data = {
            cycles_id,
            option_id,
        };

        const result = await requestUserChangeCycles(data);

        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result.status === 200) {
            const resultGetCart = await requestUserGetCart();

            if (resultGetCart.status === 401 || resultGetCart.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${router.login}?redirect_url=${pathname}`);
            } else if (resultGetCart.status === 200) {
                const totalPrice = resultGetCart.data.reduce((price, current) => {
                    return price + current.pricing.price * current.quantity;
                }, 0);

                const totalDiscount = resultGetCart.data.reduce((discount, current) => {
                    return discount + current.pricing.price * (current.pricing.discount / 100) * current.quantity;
                }, 0);

                const totalPayment = resultGetCart.data.reduce((total, current) => {
                    return total + current.pricing.price * (1 - current.pricing.discount / 100) * current.quantity;
                }, 0);

                const totalBonusPoint = resultGetCart.data.reduce((total, current) => {
                    return total + current.pricing.bonus_point;
                }, 0);

                setCarts(resultGetCart.data);
                setTotalPrice(totalPrice);
                setTotalPayment(totalPayment);
                setTotalDiscount(totalDiscount);
                setTotalBonusPoint(totalBonusPoint);
                notification.success({
                    message: 'Thông báo',
                    description: result.message,
                });
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: resultGetCart.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleAddVoucher = () => {
        if (voucher) {
            notification.error({
                message: 'Thông báo',
                description: 'Không thể áp dụng chương trình này',
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: 'Chức năng khuyến mãi đang được phát triển',
            });
        }
    };

    const handleClearCart = async () => {
        const data = carts.map((data) => data.id);
        if (data.length < 1) {
            return notification.error({ message: 'Thông báo', description: 'Giỏ hàng trống không có gì để xoá' });
        }

        const result = await requestUserClearCart(data);

        setModalVisible(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result.status === 200) {
            const { cart_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ cart_count: 0, ...others }));

            setCarts([]);
            setTotalPrice(0);
            setTotalPayment(0);
            setTotalDiscount(0);
            setTotalBonusPoint(0);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleRemoveItemCart = async (data) => {
        if (!data) {
            return notification.error({ message: 'Thông báo', description: 'Chọn sản phẩm cần xoá khỏi giỏ hàng' });
        }

        const result = await requestUserClearCart([data]);

        setModalVisible(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result.status === 200) {
            const cloneCarts = [...carts];

            const indexCart = cloneCarts.findIndex((item) => item.id === data);
            if (indexCart === -1) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy sản phẩm trong giỏ hàng',
                });
            }

            cloneCarts.splice(indexCart, 1);

            const totalPrice = cloneCarts.reduce((price, current) => {
                return price + current.pricing.price * current.quantity;
            }, 0);

            const totalDiscount = cloneCarts.reduce((discount, current) => {
                return discount + current.pricing.price * (current.pricing.discount / 100) * current.quantity;
            }, 0);

            const totalPayment = cloneCarts.reduce((total, current) => {
                return total + current.pricing.price * (1 - current.pricing.discount / 100) * current.quantity;
            }, 0);

            const totalBonusPoint = cloneCarts.reduce((total, current) => {
                return total + current.pricing.bonus_point;
            }, 0);

            setCarts(cloneCarts);
            setTotalPrice(totalPrice);
            setTotalPayment(totalPayment);
            setTotalDiscount(totalDiscount);
            setTotalBonusPoint(totalBonusPoint);

            const { cart_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ cart_count: cart_count - 1, ...others }));

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleCreateOrder = async () => {
        setLoadingPayment(true);
        const result = await requestUserPaymentCart();

        setLoadingPayment(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${router.login}?redirect_url=${pathname}`);
        } else if (result.status === 200) {
            setCarts([]);
            setTotalPrice(0);
            setTotalPayment(0);
            setTotalDiscount(0);
            setTotalBonusPoint(0);

            const { cart_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ cart_count: 0, ...others }));

            navigate(`${router.billing_orders}/${result.data}`);
            message.success(result.message);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'Sản phẩm',
            key: 'title',
            render: (data) => (
                <Fragment>
                    <div className="font-weight-bold font-size-15">{data.title}</div>
                    <div className="text-subtitle font-size-13">{data.description}</div>
                </Fragment>
            ),
        },
        {
            title: 'Chu kỳ',
            key: 'cycles',
            render: (data) => (
                <Select value={data.pricing.id} style={{ width: 120 }} onChange={(e) => handleSelectCycles(e, data.id)}>
                    {data.pricings.map((pricing, index) => (
                        <Option value={pricing.id} key={index}>
                            {pricing.cycles.display_name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Số tiền',
            key: 'price',
            render: (data) => (
                <Flex align="center" justify="space-between">
                    <div>
                        <span>{convertCurrency(data.pricing.price * data.quantity)}</span>
                        <span className="text-danger font-size-12 ml-2">-{data.pricing.discount}%</span>
                    </div>
                    <Button
                        size="small"
                        className="box-center hover-red hover-border-red ml-2"
                        onClick={() => handleRemoveItemCart(data.id)}
                    >
                        <IconTrash size={16} />
                    </Button>
                </Flex>
            ),
        },
    ];

    return (
        <div className="container">
            <Row>
                <Col span={24}>
                    <Flex className="gap-2 mb-3 ml-2">
                        <Button size="small" className="box-center" onClick={() => navigate('/billing/orders')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            className="flex-1"
                            items={[
                                {
                                    title: <Link to={router.home}>Trang chủ</Link>,
                                },
                                {
                                    title: 'Giỏ hàng',
                                },
                            ]}
                        />
                    </Flex>
                </Col>

                {!loading ? (
                    <Col span={24}>
                        <Row style={{ rowGap: 16 }}>
                            <Col md={17} style={{ padding: '0 8px' }}>
                                <Card styles={{ body: { padding: '10px 18px 18px' } }}>
                                    {carts.length > 0 ? (
                                        <Fragment>
                                            <Flex align="center" justify="space-between">
                                                <h2 className="font-size-20 font-bold mb-0">Giỏ hàng ({carts.length})</h2>

                                                <Button
                                                    danger
                                                    type="link"
                                                    className="box-center gap-1"
                                                    onClick={() => setModalVisible(true)}
                                                >
                                                    <IconTrash size={18} />
                                                    Làm trống
                                                </Button>
                                            </Flex>

                                            <Table
                                                className="mt-3 border-t-dashed border-top orders-table"
                                                columns={columns}
                                                dataSource={carts.map((cart) => ({ key: cart.id, ...cart }))}
                                                pagination={false}
                                            />
                                        </Fragment>
                                    ) : (
                                        <Flex justify="center" align="center" className="py-4">
                                            <div className="w-max-600 d-flex flex-column align-items-center">
                                                <img
                                                    src={imageEmptyCart}
                                                    alt="Empty Cart"
                                                    style={{ width: '100%', height: 'auto', maxWidth: 250 }}
                                                />
                                                <h3 className="mb-2 font-size-25 text-center mt-4">Giỏ hàng của bạn còn trống</h3>
                                                <div className="text-subtitle text-center">
                                                    Có vẻ như bạn chưa thêm bất cứ thứ gì vào giỏ hàng của mình. Hãy tiếp tục và khám phá
                                                    các sản phẩm của chúng tôi.
                                                </div>
                                            </div>
                                        </Flex>
                                    )}
                                </Card>

                                {carts.length > 0 && (
                                    <Card styles={{ body: { padding: '12px 18px 18px' } }} className="mt-5">
                                        <Flex className="flex-wrap gap-3 pb-2">
                                            <h3 className="font-size-18 font-bold mb-0">Phương thức thanh toán</h3>
                                        </Flex>

                                        <Radio.Group value={1} className="w-full">
                                            <Radio value={1} className="border-antd billing-payment-method_item">
                                                <Flex align="center" className="gap-2">
                                                    <Avatar
                                                        shape="square"
                                                        src={imageWallet}
                                                        style={{ fontSize: 18, width: 35, height: 35, lineHeight: 35 }}
                                                    />
                                                    <div>
                                                        <span className="mr-2">Ví Thegioicode</span>
                                                        <p className="text-primary mb-0 line-height-15 font-size-13">
                                                            Số dư: {convertCurrency(currentUser?.wallet?.total_balance)}
                                                        </p>
                                                    </div>
                                                </Flex>
                                            </Radio>
                                        </Radio.Group>
                                    </Card>
                                )}
                            </Col>

                            {carts.length > 0 && (
                                <Col md={7} xs={24} style={{ padding: '0 8px' }}>
                                    <Card styles={{ body: { padding: '10px 18px 18px' } }}>
                                        <h2 className="font-size-18 mb-0 ">Mã giảm giá</h2>

                                        <div className="border-top border-t-dashed mt-2 pt-2">
                                            <Input
                                                value={voucher}
                                                onChange={(e) => setVoucher(e.target.value)}
                                                placeholder="Nhập mã voucher"
                                                prefix={<IconTicket size={18} className="text-subtitle" />}
                                                maxLength={20}
                                                disabled={carts.length < 1}
                                            />

                                            <Button
                                                type="primary"
                                                block
                                                className="mt-2"
                                                disabled={voucher.length < 1 || carts.length < 1}
                                                onClick={handleAddVoucher}
                                            >
                                                Áp dụng
                                            </Button>
                                        </div>
                                    </Card>

                                    <Card styles={{ body: { padding: '10px 18px 18px' } }} className="mt-4">
                                        <h2 className="font-size-18 mb-0">Chi tiết giá</h2>

                                        <Flex className="flex-column mt-3 border-top border-t-dashed">
                                            <Flex justify="space-between" className="py-2 border-bottom border-b-dashed">
                                                <span className="font-bold">Tổng số tiền</span>
                                                <span className="font-bold">{convertCurrency(totalPrice)}</span>
                                            </Flex>
                                            <Flex justify="space-between" className="py-2 border-bottom border-b-dashed">
                                                <span className="font-bold">Chiết khấu</span>
                                                <span className="font-bold text-danger">{convertCurrency(-totalDiscount)}</span>
                                            </Flex>
                                            <Flex justify="space-between" className="py-2 border-bottom border-b-dashed">
                                                <span className="font-bold">Điểm nhận được</span>
                                                <span className="font-bold text-warning">
                                                    <span className="mr-1">{convertCurrency(totalBonusPoint).slice(0, -1)}</span>
                                                    <img src={imagePoint} alt="Point" style={{ width: 12, height: 12 }} />
                                                </span>
                                            </Flex>
                                            <Flex justify="space-between" className="border-bottom py-2">
                                                <span className="font-bold">Tổng thanh toán</span>
                                                <span className="font-bold font-size-18 text-primary">{convertCurrency(totalPayment)}</span>
                                            </Flex>

                                            <div className=" mt-4">
                                                <Button type="primary" loading={loadingPayment} block onClick={handleCreateOrder}>
                                                    {loadingPayment ? (
                                                        'Đang tạo...'
                                                    ) : (
                                                        <div className="box-center gap-1">
                                                            <IconCheck size={20} />
                                                            <span className="ml-1">Xác nhận</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </div>
                                        </Flex>
                                    </Card>
                                </Col>
                            )}

                            {modalVisible && (
                                <Modal
                                    centered
                                    closable={false}
                                    maskClosable={false}
                                    open={modalVisible}
                                    onOk={handleClearCart}
                                    onCancel={() => setModalVisible(false)}
                                    width={460}
                                    okText="Xác nhận"
                                    cancelText="Hủy"
                                >
                                    <p>Bạn có muốn xóa bỏ {carts.length} sản phẩm khỏ giỏ hàng?</p>
                                </Modal>
                            )}
                        </Row>
                    </Col>
                ) : (
                    <Flex align="center" justify="center" className="w-full" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}
            </Row>
        </div>
    );
}

export default Cart;
