import { Collapse, Flex, Table } from 'antd';

const columns = [
    {
        title: 'Loại',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Tham số',
        dataIndex: 'parameter',
        key: 'parameter',
    },
    {
        title: 'Kiểu dữ liệu',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description',
    },
];

const dataSource = [
    {
        title: 'Body JSON',
        parameter: 'pricing_id',
        type: 'Number | String',
        description: 'ID chu kỳ máy chủ muốn gia hạn',
    },
    {
        title: 'Body JSON',
        parameter: 'order_id',
        type: 'Number | String',
        description: 'ID đơn hàng máy chủ muốn gia hạn',
    },
];

const example = {
    order_id: 38260927,
    pricing_id: 77933418,
};

const responseSuccess = {
    data: {
        id: 38260927,
        plan: {
            title: 'Premium',
            image_url: 'https://thegioicode.com/images/TrchbOWyGD.png',
            description:
                'Ứng dụng đòi hỏi hiệu năng cao - Trang bị CPU Intel Xeon Gold/Platinum Gen 2 cùng với ổ cứng Enterprise NVMe siêu siêu nhanh',
            id: 28836824,
        },
        status: 'activated',
        method: 'api',
        image: {
            title: 'Debian-9.4_64bit',
            group: 'Debian',
            image_url: 'https://thegioicode.com/images/MWDpXuAIPW.png',
            description: '',
            id: 23695313,
        },
        region: {
            title: 'Hồ Chí Minh',
            image_url: 'https://thegioicode.com/images/yBdMTontlE.png',
            description: '',
            id: 82206976,
        },
        product: {
            id: 32483388,
            core: 2,
            disk: 40,
            title: 'Premium #3',
            memory: 2048,
            bandwidth: 0,
            customize: false,
            network_speed: 200,
        },
        slug_url: '2dde3629-0375-4adb-b69a-1f7633355269',
        cpu_usage: 0.2,
        auto_renew: false,
        disk_usage: 0.8408203125,
        description: '',
        memory_usage: 0,
        display_name: 'ubuntu2004',
        backup_server: false,
        override_price: 1155000,
        bandwidth_usage: 0.02,
        order_info: {
            port: 22,
            hostname: 'ubuntu2004',
            username: 'root',
            password: 'M!v1orI0#8Qj',
            access_ipv4: '161.248.4.8',
            access_ipv6: '',
        },
        created_at: '2025-01-13 23:23:37',
        expired_at: '2025-10-13 23:59:59',
    },
    status: 200,
    message: 'Gia hạn máy chủ thành công',
};

function Renew() {
    return (
        <Collapse
            items={[
                {
                    key: '1',
                    label: <div className="font-bold text-hover">Gia hạn máy chủ</div>,
                    children: (
                        <div>
                            <Flex align="center">
                                <span className="document-method text-warning font-bold">POST</span>
                                <span className="document-method flex-1 text-start">https://thegioicode.com/api/v2/cloud-server/renew</span>
                            </Flex>

                            <Table className="mt-4" bordered columns={columns} dataSource={dataSource} pagination={false} />

                            <div className="mt-3 mb-1">Body:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy warning">{JSON.stringify(example, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>

                            <div className="mt-3 mb-1">Thành công:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy success">{JSON.stringify(responseSuccess, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>

                            <div className="mt-3 mb-1">Thất bại:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy">{JSON.stringify({ error: 'Thông tin lỗi trả về' }, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    );
}

export default Renew;
