import { Collapse, Flex, Table } from 'antd';

const columns = [
    {
        title: 'Loại',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Tham số',
        dataIndex: 'parameter',
        key: 'parameter',
    },
    {
        title: 'Kiểu dữ liệu',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description',
    },
];

const dataSource = [
    {
        title: 'Params',
        parameter: 'order_id',
        type: 'Number | String',
        description: 'ID đơn máy chủ muốn lấy thông tin',
    },
];

const responseSuccess = {
    data: [
        {
            pricing: {
                price: 672000,
                discount: -168000,
                cycles: '12 Tháng',
            },
            id: 33735238,
            disk: 80,
            core: 4,
            title: 'Premium #6',
            memory: 8192,
            status: true,
            priority: 6,
            sold_out: false,
            customize: false,
            bandwidth: 0,
            description: '',
            network_speed: 200,
        },
        '...',
    ],
    status: 200,
    message: 'Lấy thông tin nâng cấp cấu hình thành công',
};

function ResizeInfo() {
    return (
        <Collapse
            items={[
                {
                    key: '1',
                    label: <div className="font-bold text-hover">Lấy thông tin cấu hình muốn đổi</div>,
                    children: (
                        <div>
                            <Flex align="center">
                                <span className="document-method text-success font-bold">GET</span>
                                <span className="document-method flex-1 text-start">
                                    https://thegioicode.com/api/v2/cloud-server/resize/:order_id
                                </span>
                            </Flex>

                            <Table className="mt-4" bordered columns={columns} dataSource={dataSource} pagination={false} />

                            <div className="mt-3 mb-1">Thành công:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy success">{JSON.stringify(responseSuccess, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>

                            <div className="mt-3 mb-1">Thất bại:</div>
                            <div>
                                <div className="copy">
                                    <pre>
                                        <code className="text-copy">{JSON.stringify({ error: 'Thông tin lỗi trả về' }, null, 2)}</code>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    );
}

export default ResizeInfo;
