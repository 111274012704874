import { Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { IconMail } from '@tabler/icons-react';
import { Button, Input, Form, Spin, notification, Alert } from 'antd';

import Account from '../Account';
import router from '~/configs/routes';
import { requestUserResetPassword } from '~/services/auth';

function Reset() {
    const [alert, setAlert] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [form] = Form.useForm();

    const handleSubmitResetPassword = async (values) => {
        const { email } = values;
        setLoadingButton(true);

        const result = await requestUserResetPassword(email);

        setLoadingButton(false);
        if (result?.status === 200) {
            setAlert(true);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Account>
            <Fragment>
                <h3 className="mb-1 font-weight-bold font-size-30">Quên mật khẩu</h3>
                <p className="mb-2 text-center">
                    Nhập địa chỉ email đã đăng ký của bạn và chúng tôi sẽ gửi cho bạn một liên kết để thiết lập lại mật khẩu.
                </p>

                {alert && (
                    <Alert
                        className="mb-2"
                        message="Vui lòng kiểm tra email để lấy lại mật khẩu, trường hợp bạn không nhận được email vui lòng kiểm tra trên thư mục spam, junk mail..."
                        type="success"
                    />
                )}

                <Form form={form} name="horizontal_login" onFinish={handleSubmitResetPassword}>
                    <Form.Item
                        name="email"
                        className="mt-6"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập email',
                            },
                            {
                                type: 'email',
                                message: 'Email không đúng định dạng',
                            },
                        ]}
                    >
                        <Input size="large" autoFocus prefix={<IconMail />} readOnly={alert} placeholder="Nhập địa chỉ email của bạn" />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                disabled={loadingButton || alert}
                                style={{ width: '100%' }}
                            >
                                {loadingButton ? <Spin /> : <Fragment>GỬI YÊU CẦU</Fragment>}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
                <Link to={router.login}>
                    <Button size="large" block className="text-uppercase font-weight-bold">
                        Về trang đăng nhập
                    </Button>
                </Link>
            </Fragment>
        </Account>
    );
}

export default Reset;
